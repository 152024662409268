import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    GENERAL: "General",
    SOFTWARE: "Software",
    CERTIFICATE: "Certificate",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "E-Borrow",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I worked on a project at King Mongkut's Institute of Technology Ladkrabang (KMITL) to modernize an outdated inventory system. My team and I developed a new web application using Next.js for the frontend and PostgreSQL for the database, integrating Google Firebase for account management and image storage. As a full-stack developer, I collaborated closely with my team through regular meetings, improving both my technical and communication skills. This project strengthened my ability to work in an international team, enhanced my proficiency in Next.js, and reinforced my problem-solving skills in web development.",
        client: "KMITL",
        technologies: "Next.js, PostgreSql, Firebase and Typescript",
        industry: "University",
        date: "Feb 2024 - May 2024",
        url: {
          name: "Github Repo",
          link: "https://github.com/JarneDirken/2024-InternshipAssignment",
        },
        urlPreview: {
          name: "E-Borrow",
          link: "https://2024-internship-assignment.vercel.app/",
        },
        sliderImages: [
          "images/projects/E-Borrow_login.png",
          "images/projects/E-Borrow_dashboard.png",
        ],
      },
      thumbImage: "images/projects/E-Borrow_home.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "VisiAge",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I worked on a project with my team to create a mobile application for the client Mediaan as part of the Visi-Age initiative. We employed the Scrum methodology to guide our development process, using React for the frontend and .NET 7 for the backend, with MySQL for the database. Our collaborative effort was highly effective, with each team member contributing and supporting one another. I was primarily responsible for the frontend development of the application and played a key role in creating wireframes and implementing the backend functionalities. This project allowed me to enhance my skills in React, work extensively with a MySQL database, and significantly improve my mobile app design and problem-solving abilities. Additionally, I gained valuable experience in applying the Scrum method and working efficiently within a team setting. I am grateful for my team’s support and collaboration throughout this project, which ensured a successful and well-rounded solution for our client.",
        client: "Mediaan",
        technologies: "React Native, .Net and MySQL",
        industry: "Healthcare",
        date: "2nd Semester 2023 - 2024",
        url: {
          name: "Github Repo",
          link: "https://github.com/SohaibIbenhajene/Project4.0-VisiAge",
        },
        video: "images/projects/caring_minds_promo.mp4",
        video_thumbnail: "images/projects/visiage_video.png",
      },
      thumbImage: "images/projects/visiage.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "TripPlanner",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I worked on a project with my team to create a web application for a trip planner. We used the Scrum method to develop the application and utilized Angular for the frontend, .NET C# for the backend, and MySQL for the database. Our team collaborated effectively, with everyone contributing and supporting each other. I was primarily responsible for the frontend development of the application and creating the wireframes. Throughout this project, I sharpened my Angular skills, worked with a MySQL database, and significantly improved my web design and problem-solving abilities. I also learned how to implement the Scrum method and how to work effectively in a team. I am very thankful for my team, as they always supported me, and we worked well together.",
        client: "School Project",
        technologies: "Angular, .Net and MySQL",
        industry: "Planning",
        date: "1st Semester 2023 - 2024",
        url: {
          name: "Github Repo",
          link: "https://github.com/JarneDirken/2024-Angular_Project/",
        },
        urlPreview: {
          name: "TripPlanner",
          link: "https://2024-angular-project.vercel.app/",
        },
        sliderImages: [
          "images/projects/trip-plan.png",
          "images/projects/trip-manage.png",
          "images/projects/trip-personal.png",
        ],
      },
      thumbImage: "images/projects/trip-homepage.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "Project PHP",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I worked on a project with my team, creating a web application for a client. We used the Scrum method to develop the application and utilized PHP, HTML, CSS, JavaScript, Livewire, Tailwind, and MySQL. Our team collaborated effectively, with everyone contributing and supporting each other. I was primarily responsible for the registration part of the application. Although we were still working on the project at the time, it was nearing completion. Throughout this project, I sharpened my PHP skills, worked with a MySQL database, and significantly improved my web design and problem-solving abilities. I also learned how to implement the Scrum method and how to work effectively in a team. I am very thankful for my team, as they always supported me and we worked well together.",
        client: "De Stickies",
        technologies: "PHP, JavaScript, Livewire, Tailwind and MySQL",
        industry: "Sports",
        date: "2nd Semester 2022 - 2023",
        urlPreview: {
          name: "DeStickies",
          link: "https://smoa.destickies.be/",
        },
        sliderImages: [
          "images/projects/php-calendar.png",
          "images/projects/php-register.png",
        ],
      },
      thumbImage: "images/projects/php-homepage.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "Maze Game",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I created a maze game using Windows Forms. It was programmed in c# and I also implemented a SQLite database. The game is very simple, first you can choose your difficulty and fill in your username. Once you click play you'll be placed inside the maze, you then have get to the end as fast as possible without touching the walls. If you touch a wall you'll be brought back to the beginning and lose a life. Depending on the difficulty you'll have more or less lives. Once you finish you'll be presented with a screen where you can see your score and also all the other highscores. During this project I sharpened my c# skills and worked with a SQLite database for the first time. It is the first game that I created and defenitely not the last. I really enjoyed working on this project, because it was so rewarding. After coding for hours, I could actually start playing my game.",
        client: "School Project",
        technologies: "c#, SQLite",
        industry: "Game",
        date: "1st Semester 2022 - 2023",
        url: {
          name: "Github Repo",
          link: "https://github.com/SohaibIbenhajene/CaseStudyDevops",
        },
        sliderImages: [
          "images/projects/maze-game.png",
          "images/projects/maze-end.png",
        ],
      },
      thumbImage: "images/projects/maze-start.png",
      categories: [filters.SOFTWARE],
    },
    {
      title: "Reply cyber security challenge",
      type: types.IMAGE,
      thumbImage: "images/projects/reply-certificate.png",
      categories: [filters.CERTIFICATE],
    },
    {
      title: "Android 14 App Development Bootcamp 2024",
      type: types.IMAGE,
      thumbImage: "images/projects/udemy-certificate.jpg",
      categories: [filters.CERTIFICATE],
    },
    {
      title: "Webdesign Advanced",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I created a learning website using HTML, JavaScript and Sass. I used Sass for the first time and at first it was really difficult. But once I got the hang of it, it was really easy to use. I also used a lot of animations and transitions to make the website more interactive. I also used JavaScript for the very first time. I used it to create a hamburger menu for the mobile version of the website. The website was basically made for a made up company, that would sell courses to learn how to code in different programming languages. During this project I got better at leading a team and I also learned how to work with the Scrum method. I also learned how to work with Sass and JavaScript. I also learned how to deal with problems that occur during teamwork in a project. Such as bad communication, sometimes not meeting deadlines and not doing your part of the project. But all of these stuggles ultimately made us a stronger team, because we learned from our mistakes and we learned how to deal with these problems. So, I'm very thankful for my team.",
        client: "School Project",
        technologies: "HTML, JavaScript and Sass",
        industry: "Technology",
        date: "2nd Semester 2021 - 2022",
        urlPreview: {
          name: "EzTyping",
          link: "https://eztyping.netlify.app/",
        },
        sliderImages: [
          "images/projects/webadvanced-java.png",
          "images/projects/webadvanced-lua.png",
        ],
      },
      thumbImage: "images/projects/webadvanced-homepage.jpg",
      categories: [filters.SOFTWARE],
    },
    {
      title: "Requirements Analysis",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "For my Requirements Analysis group project, we worked on a new system for a client who sells items at festivals and supplies other businesses. The goal was to make their inventory management more efficient, as their current system was time-consuming and labor-intensive. The client’s inventory was stored in unsorted boxes, making it difficult to find and track items. We created a use case model and gathered feedback from the client to develop prototypes for the new application. This system aims to reduce manual stock checks and improve efficiency. My role was to create prototypes and help design the use case model. I also asked questions during client meetings to understand their needs better. This project greatly improved my communication skills, both verbal and written.",
        client: "School Project",
        industry: "Technology",
        date: "1st Semester 2022 - 2023",
        sliderImages: [
          "images/projects/prototype.png",
        ],
      },
      thumbImage: "images/projects/use-case-model.jpg",
      categories: [filters.GENERAL],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section custom-bg-dark"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-white px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp text-white">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
