import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section custom-bg-dark">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-white px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp text-white">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row text-white">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Sohaib Ibenhajene
              </span>
            </h2>
            <p className="text-5">
              I'm a junior software developer with a passion for building web 
              applications. I enjoy learning and improving my skills in Java 
              and full-stack development, creating clean and efficient code. 
              I'm eager to grow as a developer and build software that provides 
              real value. My focus is on writing maintainable code and continuously 
              expanding my knowledge to become a better developer.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  3
                </span>
              </div>
              <h3 className="text-7 wow" data-wow-delay="2s">
                Years of <span className="fw-700">Coding Experience</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-white fw-600 mb-0">Sohaib Ibenhajene</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="" href="mailto:sohaib.ib@outlook.com">
                sohaib.ib@outlook.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-white fw-600 mb-0">25 September, 2003</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-white fw-600 mb-0">Turnhout, Belgium.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
