import React from "react";
import resumeFile from "../documents/CV_Sohaib_portfolio.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2021 - 2024",
      title: "Bachelor Degree - Magna Cum Laude",
      place: "Thomas More Geel",
      desc: "Bachelor's Applied Computer Science - Application Development",
    },
    {
      yearRange: "2015 - 2021",
      title: "High School Degree",
      place: "Heilig-Graf Turnhout",
      desc: "IT & Networks",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "MAR 2024 - MAY 2024",
      title: "Computer Engineering Intern",
      place: "KMITL, Bangkok, Thailand",
      desc: "Working as an intern in the computer engineering faculty on a web application in Next.js.",
    },
    {
      yearRange: "2022 - 2023",
      title: "Factory Worker",
      place: "B-Token, Retie",
      desc: "Working parttime in a factory. Working independently with a token production machine, based on customer orders.",
    },
    {
      yearRange: "FEB 2023 - JUN 2023",
      title: "Delivery Driver",
      place: "Cornelissen, Turnhout",
      desc: "Delivering groceries to customers' homes while driving a van solo.",
    },
    {
      yearRange: "APR 2022",
      title: "Factory Worker",
      place: "Cartamundi, Turnhout",
      desc: "Working parttime, working together with coworkers to pack playing cards.",
    },
  ];

  const skills = [
    {
      name: "Java",
      percent: 70,
    },
    {
      name: "Spring",
      percent: 65,
    },
    {
      name: "SQL",
      percent: 75,
    },
    {
      name: "HTML/CSS",
      percent: 85,
    },
    {
      name: "JavaScript",
      percent: 75,
    },
    {
      name: "React JS",
      percent: 80,
    },
    {
      name: "Angular Js",
      percent: 60,
    },
    {
      name: "Next Js",
      percent: 70,
    },
  ];

  return (
    <section id="resume" className="section custom-bg-dark">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-white px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp text-white">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5 text-white">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp text-white">My Skills</h2>
        <div className="row gx-5 text-white">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-warning shadow-none rounded-0"
            target="blank"
            href={resumeFile}
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
